import styled from "@emotion/styled";
import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

const List = styled.ul`
  li {
    margin-bottom: 1rem;
  }
`;
// markup
const Page = ({ location }) => {
  return (
    <Layout path={location.pathname} title="תקנון">
      <SectionContent title="תקנון">
        <List>
          <li>המסלול בשום שלב לא שייך לרצים בלבד</li>
          <li>
            המרוץ הוא בדגש על החיבור בין הרצים לעוטף עזה קהילה ביטחון ספורט
            וטבע. ולכן הנושא התחרותי אינו בראש סדר העדיפויות. ולא יהיו פרסים
            מיוחדים לזוכים.
          </li>
          <li>
            ההשתתפות היא על אחריות הרצים נדרשת חתימה בתהליך ההרשמה על הצהרת
            בריאות. והסרת אחריות.
          </li>
          <li>מתחת גיל 18 מחייב אישור בכתב הורים</li>
          <li>אנשי צוות יהיו מודרכים ולובשים ווסט צהוב לזיהוי בכל שלב</li>
          <li>
            זמן מטאטא 8 קמ"ש מזינוק. מי שיורד מזמן זה עולה על רכב האיסוף. מי שלא
            עולה. מוציא את עצמו אוטומטי מאחריות המארגנים. ויחד עם זאת חייב לעלות
            לרכב המאסף.
          </li>
          <li>איסור ריצה עם בעלי חיים. גם עם רצועה .</li>
          <li>
            עזרה ראשונה. צוותים רפואיים יהיו זמינים החל מזינוק דרך המסלול ועד
            לסיום. מס טלפון של הצוות הרפואי יימסר בדף הקשר לרצים מבעוד מועד.
          </li>
          <li>חנייה ותנועת רכבים לאורך המסלול אסורה.</li>
          <li>
            המסלול איננו סטרילי. באחריות הרצים לדעת ולהתנהל בהתאם. אין זכות
            ראשונה לרצים בכל שלב. וחובה לרוץ בהתאם לחוקים וכללים המקובלים.
          </li>
          <li>
            יחד עם זאת. רכבי קבוצה לא רשאים לנסוע על המסלול.. מה שיביא לפסילת
            הקבוצה. התנועה לתחנות תתבצע בדרכים הראשיות והחלופיות בלבד. כניסה
            לשטח החלפה רק לרכבים עם דגלון המרוץ.
          </li>
          <li>חציית כביש גבולות צאלים כביש מס 222 .</li>
          <li>ו חציית כביש יד מורדכי מעבר ארז כביש מס 4.</li>
          <li>
            מחייבת נוכחות של משטרה . ועדיין אחריות הרצים לחצות בהתאם לחוקי
            התנועה. הכביש לא סגור.
          </li>
          <li>הרצים יצוידו במפת מסלול . ומספרי טלפון של</li>
          <li>
            מדא. מארגן. סיוע רכוב. משטרה. דגל מרוץ לרכבים. ומס חזה לכל משתתף.
          </li>
          <li>הצמיד להחלפה בין הרצים.</li>
          <li>
            אין לרוץ על כבישים . ובכל מקרה להישמע להנחיות הסדרנים ומשטרה לאורך
            המרוץ כולו.
          </li>
          <li>סימון המסלול עם חיצים. סרטי סימון ושלטים</li>
          <li>
            בנוסף במקומות רגישים יהיו אנשי צוות. או סימון מודגש של מפגעים כל
            שהם. על המסלול או בקרבתו. בשאיפה יהיה בדף המרוץ ציון של נקודות
            בעיתיות על המסלול.
          </li>
          <li>אחריות הרצים להכיר את מסלול או מקטע המרוץ שעליהם לעבור.</li>
          <li>אחריות הקבוצות לנייד את הרצים בין תחנות ההחלפה .</li>
          <li>
            כמו כן הרצים אחראים על המזון ושתיה שלהם למהלך הריצה. ללא תלות בתחנות
            ההחלפה שבדרך.
          </li>
          <li>חובה לרוץ עם כובע ומים.</li>
          <li>
            סימון פניות . צמתים. לפני ואחרי כדי שרצים ידעו שהם על המסלול הנכון.
            באחריות המארגנים.
          </li>

          <li>סימון המרחקים לאורך הציר ומספור של תחנות ההחלפה.</li>
          <li>נוהל החלפת רצים בתוך הקבוצה. אין חובה להתחלף בסדר קבוע.</li>
          <li>
            החלפה בין רצים מול מנהל תחנת ההחלפה ולוודא שההחלפה נרשמה בטופס
            התחנה. לבקרה.
          </li>
          <li>
            קשר ותקשורת בין הצוותים. המארגנים. מדא. משטרה. צבא. ...באחריות
            אוגדה.
          </li>
          <li>מזג האוויר תחזית מעודכנת. באתר.</li>
          <li>
            מתנדבים . נקבל בברכה עזרת מתנדבים בתחנות ההחלפה. ואספקת כיבוד קל
            לתחנות עבור הרצים. עדיף בתאום מראש בצור קשר.
          </li>
          <li>תחנות החלפה</li>
          <li>בכל תחנה יהיה מנהל תחנה וסדרנים. עוקב מים</li>
          <li>
            ומזון קל כמו ממרחים. לחם. פירות או כל מזון שיסופק בהתנדבות. או דרך
            האוגדה.
          </li>
          <li>
            נק שתיה צבא. בנוסף על העוקבים יהיו כל 2.5 קמ נקודת שתיה לפי דרישות
            הצבא. המים הנל הם לשימוש חיילים ולחרום בלבד, באחריות הרצים לרוץ עם
            מספיק מים בין תחנות ההחלפה.
          </li>
          <li>הודעה על פרישה מהמרוץ</li>
          <li>
            חובה להודיע למנהל התחנה שאליה לא הגיע הרץ. ולמרכז השליטה שמספרו
            יופיע בדף המידע.
          </li>
          <li>מבנה תחנות החלפה</li>
          <li>
            שטח הכינוס וזינוק כמו שטח הסיום הינם באחריות המארגנים . כולל שער
            זינוק. סיום.
          </li>
          <li>סבלנות המשתתפים</li>
          <li>נוהל הלך לאיבוד</li>
          <li>יעצור במקום. יחפש דרך ליצור קשר עם המארגנים לפי דף הקשר.</li>
          <li>אסור לסייע לרץ מרכב נוסע במהלך המרוץ בשום שלב.</li>
          <li>
            בטיחות וביטחון הרצים הוא התנאי לקיום והמשך המרוץ. ברמה האישית
            והארגונית.
          </li>
          <li>צבע אדום. הפסקת המרוץ וחבירה לתחנות ההחלפה.</li>
        </List>
      </SectionContent>
    </Layout>
  );
};

export default Page;
